<template>
    <form @submit.prevent="submit" class="styled-form">

      <!-- Ticketnumber -->
      <div class="form-row">
        <label for="ticketnumber">{{ $t("Ticket number") }}</label>
        <input v-model="$v.ticketnumber.$model" :disabled="formLoading" type="text" name="ticketnumber" id="ticketnumber">
        <span class="error" v-if="$v.ticketnumber.$dirty && !$v.ticketnumber.required">{{ $t("Field is required") }}</span>
      </div>

      <!-- Email -->
      <div class="form-row">
        <label for="email">{{ $t("Email address") }}</label>
        <input v-model="$v.email.$model" :disabled="formLoading" type="email" name="email" id="email">
        <span class="error" v-if="$v.email.$dirty && !$v.email.required">{{ $t("Field is required") }}</span>
        <span class="error" v-else-if="$v.email.$dirty && !$v.email.email">{{ $t("This is not a correct email address") }}</span>
      </div>

       <!-- Recaptcha -->
      <div class="form-row">
         <vue-recaptcha sitekey="6LeORZccAAAAAO-S_MMoHiHOjfD5pS2G2uPUDYzo" ref="recaptcha" @verify="onVerifyRecaptcha" @expired="recaptcha = ''"></vue-recaptcha>
         <span class="error" v-if="$v.recaptcha.$dirty && !$v.recaptcha.required">{{ $t("Field is required") }}</span>
      </div>

      <div class="form-row" v-if="formErrors.length > 0 && !$v.$anyDirty">
        <span class="error" v-for="error in formErrors" :key="error.description">{{ $t(error.description) }}</span>
      </div>

      <div class="form-row">
        <input type="submit" :disabled="formLoading" class="btn btn-primary" :class="formLoading?'loading':''" :value="$t('Submit form')">
      </div>

    </form>
</template>

<script>
import { required, email } from 'vuelidate/lib/validators'
import ApiService from '@/services/api.service'
import router from '@/router/'

import VueRecaptcha from 'vue-recaptcha'

export default {
  name: 'RejectForm',
  props: {
    guid: String
  },
  components: {
    VueRecaptcha
  },
  data () {
    return {
      ticketnumber: null,
      email: '',
      recaptcha: '',
      formLoading: false,
      formErrors: []
    }
  },
  validations: {
    ticketnumber: { required },
    email: { required, email },
    recaptcha: { required }
  },
  beforeCreate () {},
  mounted () {},
  computed: {},
  methods: {
    onVerifyRecaptcha: function (response) {
      this.recaptcha = response
    },
    submit () {
      this.formLoading = true
      this.formError = null
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.$v.$reset()

        ApiService.post('/Recaptcha', {
          token: this.recaptcha
        })
          .then(response => {
            if (response.data.success && response.data.data.result.valid) {
              this.reject()
            } else if (response.data.errors.length > 0) {
              this.formLoading = false
              this.formErrors = response.data.errors
            } else {
              this.formLoading = false
              this.formErrors = [{ description: 'Something went wrong. Please try again.' }]
            }
            this.$refs.recaptcha.reset()
          })
          .catch(error => {
            if (error.response.data.errors && error.response.data.errors.length > 0) {
              this.formLoading = false
              this.formErrors = error.response.data.errors
            } else {
              this.formLoading = false
              this.formErrors = [{ description: 'Something went wrong. Please try again.' }]
            }
            this.$refs.recaptcha.reset()
          })
      } else {
        this.formLoading = false
      }
    },
    reject () {
      this.formLoading = true
      this.formError = null
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.$v.$reset()
        ApiService.put('/Tickets/Reject', {
          Id: parseInt(this.ticketnumber),
          Guid: this.guid,
          Email: this.email
        })
          .then(response => {
            if (response.data.success) {
              this.$v.$reset()
              router.push({ name: 'RejectSuccess' })
            } else if (response.data.errors.length > 0) {
              this.formLoading = false
              this.formErrors = response.data.errors
            } else {
              this.formLoading = false
              this.formErrors = [{ description: 'Something went wrong. Please try again.' }]
            }
            this.$refs.recaptcha.reset()
          })
          .catch(error => {
            if (error.response.data.errors && error.response.data.errors.length > 0) {
              this.formLoading = false
              this.formErrors = error.response.data.errors
            } else {
              this.formLoading = false
              this.formErrors = [{ description: 'Something went wrong. Please try again.' }]
            }
            this.$refs.recaptcha.reset()
          })
      } else {
        this.formLoading = false
        this.$refs.recaptcha.reset()
      }
    }
  },
  watch: {}
}
</script>
